import axios from "axios";
import { $ss } from "@/plugins/localstorage";

const quickCreatorBaseUrl =  process.env.VUE_APP_QC_BASE_URL;

export const getTemplates = async (sessionId) => {
    try {
        const response = await axios.get(`${quickCreatorBaseUrl}/templates`,
            {
            headers: {
                'Content-Type': 'application/json',
                'x-mono-sid': sessionId
            }
        });
        return response.data;
    } catch (error) {
        console.log('GET TEMPLATES ERROR --- ', error);
        if (error.message === 'Network Error') {
            console.error('Network Error:', error);
            $ss.set('loginToken', '');
            return 'There was a network error. Please try again later.';
        } else {
            console.error('GET TEMPLATES ERROR', error);
            return 'An error occurred while retrieving templates.';
        }
    }
};

export const getTemplateContentById = async (sessionId, templateId) => {
    try {
        const response = await axios.get(`${quickCreatorBaseUrl}/templates/${templateId}`,
            {
            headers: {
                'Content-Type': 'application/json',
                'x-mono-sid': sessionId
            }
        });
        return response.data;
    } catch (error) {
        console.log('GET TEMPLATES BY ID ERROR --- ', error);
        if (error.message === 'Network Error') {
            console.error('Network Error:', error);
            $ss.set('loginToken', '');
            return 'There was a network error. Please try again later.';
        } else {
            console.error('GET TEMPLATES ERROR', error);
            return 'An error occurred while retrieving templates.';
        }
    }
};