<template>
    <div class="themesViewPalette">
        <div class="themesContent">
            <span class="preamble">{{ $t("quickCreator.templateSelection.colorPalettes.preamble") }}</span>
            <div class="paletteOptions">
                <span class="resetTemplate" @click.prevent="resetTemplate">{{ $t("quickCreator.templateSelection.colorPalettes.reset") }}</span>
                <input-multiselect classType="normal" :value="palettesOptions[0]" :options="palettesOptions"
                    :multiple="false" closeOnSelect @change="changeDisplayedPalettes" />
            </div>
            <div class="paletteColors">
                <div v-for="(palette, key) in paletteData" :key="key">
                    <color-pill :palette="palette" :class="{ active: key === selectedPaletteKey }"
                        @colorPillClick="colorClicked(key)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ColorPill, InputMultiselect } from "@monosolutions/vue-components";
import palettes from '../assets/json/palettes.json'

import Colors from '../utils/Colors';

export default {
    name: 'qc-color-palettes',
    components: { 'color-pill': ColorPill, InputMultiselect },
    data() {
        const palettesOptions = Object.keys(palettes).map(palette => ({
            text: palette
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            value: palette
        }));

        return {
            loading: false,
            palettesOptions,
            paletteData: palettes[palettesOptions[0].value],
            selectedPalette: palettes[palettesOptions[0].value],
            selectedPaletteKey: null
        }
    },
    methods: {
        resetTemplate() {
            this.selectedPaletteKey = null;
            this.$store.dispatch('setSelectedColors', {});
            Colors.removeCssRulesFromIframes();
        },
        colorClicked(payload) {
            this.selectedPaletteKey = payload;
            this.$store.dispatch('setSelectedColors', this.selectedPalette[payload]);
            Colors.addCssRulesFromPalette(this.selectedPalette[payload]);
        },
        changeDisplayedPalettes(payload) {
            const paletteKey = payload.data.value;
            this.selectedPalette = palettes[paletteKey];
            this.selectedPaletteKey = null;

            if (palettes[paletteKey]) {
                this.paletteData = palettes[paletteKey];
            }
        }
    }
};
</script>
