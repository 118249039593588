<template>
    <footer class="footer footer-full" :class="{startFlow: showStartFlowButton}">
        <!-- Back -->
        <mv-button v-if="showBackButton" class="borderless back" icon="arrow-left" text="quickCreator.buttons.back" @click="prevStep" />

        <!-- Next -->
        <mv-button v-if="showNextButton && !isLastStep" class="borderless next" :disabled="!canGoToNext" :isLoading="nextButtonLoading" text="quickCreator.buttons.next" @click="nextStep(false)" />

        <!-- Skip -->
        <mv-button v-if="showSkipButton && !isLastStep" class="borderless alternative" text="quickCreator.buttons.skip" color="white" @click="nextStep(true)" />

        <!-- Finish: Create another webstite -->
        <mv-button v-if="isLastStep" class="borderless next lastStep" :disabled="!canFinishFlow" :isLoading="finishFlowLoading" text="quickCreator.buttons.finish" @click="finishFlow(true)" />

        <!-- Start another webstite -->
        <mv-button v-if="showStartFlowButton" class="borderless create" text="quickCreator.buttons.createSite" @click="startFlow(true)" />
        
        <mv-icon-sprite />
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import { MvButton, MvIconSprite } from "@monosolutions/vue-components";
import QCModuleMixin from '../mixins/QCModuleMixin';
import QCNavigationMixin from '../mixins/QCNavigationMixin';

export default {
    name: 'qc-footer',
    components: { MvButton, MvIconSprite },
    data: () => {
		return {
			finishFlowLoading: false
		};
	},
    mixins: [ QCModuleMixin, QCNavigationMixin ],
    computed: {
        ...mapGetters({
            stepsDone: 'stepsDone',
            stepsReady: 'stepsReady',
            currentStepSettings: 'currentStepSettings',
            sessionId: 'sessionId',
            nextButtonLoading: 'nextButtonLoading'
        }),
        module() {
            return this.allModules[this.currentStepIndex];
        },
        hideFooter() {
            return (!this.showSkipButton && !this.showNextButton && !this.showBackButton && !this.showStartFlowButton) || this.currentStepSettings.hideFooter;
        },
        showBackButton() {
            if (this.$route.name === 'preview' || this.$route.name === 'loading') {
                return false;
            }
            return this.stepHistory.length > 0;
        },
        showNextButton() {
            return parseInt(this.$route.params.step) < this.allModules.length && !this.currentStepSettings.hideNext;
        },
        showSkipButton() {
            return parseInt(this.$route.params.step) < this.allModules.length && !this.module.required;
        },
        isLastStep() {
            if (this.$route.name === 'preview' || this.$route.name === 'loading') {
                return false;
            }
            return parseInt(this.$route.params.step) === this.allModules.length || this.module?.lastStep;
        },
        canFinishFlow() {
            return ((this.module.required && this.stepsReady[this.currentStepIndex]) || !this.module.required);
        },
        canGoToNext() {
            return this.module && this.stepsReady[this.currentStepIndex];
        },
        showStartFlowButton() {
            return this.$route.name === 'preview';
        }
    },
    watch: {
        hideFooter(value) {
            this.$store.dispatch('setFooterIsHidden', value);
        }
    },
    mounted() {
        this.$store.dispatch('setFooterIsHidden', this.hideFooter);
    },
    methods: {
        async finishFlow() {
            this.$router.push('/loading').catch(()=>{});
        },
        startFlow() {
            this.$store.dispatch('setCurrentStepIndex', 0);
            this.$router.push({ name: 'step', params: { step: 1 } });
        },
    }
};
</script>
