<template>
    <div>
        <div class="color-picker">
            <div class="selected-colors">
                <div v-for="(color, key) in colorsDisplayed" :key="key" :style="{ backgroundColor: color.hex }"
                    :class="{ active: selectedColorKey === key }" class="color-block"
                    @click.prevent="selectColor(key, color.hex)" />
            </div>
            <div class="color-panel">
                <div class="colorPicker">
                    <div class="ivu-color-picker-panel">
                        <Saturation v-model="colorsModel" @change="colorChange" />
                    </div>
                </div>
            </div>
            <div class="color-picker-inputs">
                <input-text class="mds-input-text" index="color" v-model="selectedColorName"
                    @change="updateColorSelected" />
                <input-multiselect classType="normal" :value="selectOptions[0]" :options="selectOptions"
                    :multiple="false" closeOnSelect @change="changeDisplayedColor" />
            </div>
            <div class="color-picker-hue-alpha">
                <div class="ivu-color-picker-picker-hue-slider">
                    <Hue v-model="colorsModel" @change="colorChange" />
                </div>
                <div class="ivu-color-picker-picker-alpha-slider">
                    <Alpha v-model="colorsModel" @change="colorChange" />
                </div>
            </div>
        </div>
        <saved-colors :selected-color-name="selectedColorName" @select-saved-color="applySavedColor" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import tinycolor from 'tinycolor2';
import { InputText, InputMultiselect } from "@monosolutions/vue-components";

import Saturation from '../../node_modules/iview/src/components/color-picker/saturation';
import Hue from '../../node_modules/iview/src/components/color-picker/hue';
import Alpha from '../../node_modules/iview/src/components/color-picker/alpha';

import Colors from '../utils/Colors';
import SavedColors from "./SavedColors.vue";

export default {
    name: 'qc-color-picker',
    components: { Saturation, Hue, Alpha, InputText, InputMultiselect, SavedColors },
    computed: {
        ...mapGetters({
            selectedColors: 'selectedColors',
            templateGlobalColors: 'templateGlobalColors',
        }),
        colorsModel: {
            get() {
                return this.val;
            },
            set(newVal) {
                this.val = newVal;
                this.changeData(this.formatColor);
            }
        },
        formatColor() {
            const { r, g, b, a } = this.colorsModel.rgba;
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        },
    },
    watch: {
        selectedColors: {
            handler(newVal) {
                if (newVal && Object.keys(newVal).length) {
                    this.colorsDisplayed = structuredClone(newVal);
                }
            },
            deep: true,
            immediate: true
        },
    },
    data() {
        return {
            colorsDisplayed: {},
            val: Colors._colorChange(''),
            selectedColorKey: null,
            selectedColorRGB: '',
            selectedColorName: '',
            selectedType: 'hex',
            selectOptions: [
                { text: "HEX", value: "hex" },
                { text: "RGB", value: "rgb" }
            ],
        }
    },
    mounted() {
        this.setColors();
    },
    methods: {
        setColors() {
            const hasSelectedColors = Object.keys(this.selectedColors).length > 0;
            const hasTemplateColors = Object.keys(this.templateGlobalColors).length > 0;

            this.colorsDisplayed = structuredClone(
                hasSelectedColors ? this.selectedColors : hasTemplateColors ?
                    Object.keys(this.templateGlobalColors).slice(0, 4).reduce((result, key) => {
                        result[key] = this.templateGlobalColors[key];
                        return result;
                    }, {}) : {}
            );

            this.selectedColorKey = Object.keys(this.colorsDisplayed)[0] || null;
            this.updateColorDetails(this.colorsDisplayed[this.selectedColorKey]?.hex);
        },
        updateColorDetails(hex) {
            if (!hex) {
                this.resetColorDetails();
                return;
            }

            const colorObj = tinycolor(hex);
            const { r, g, b, a } = colorObj.toRgb();
            this.selectedColorName = (this.selectedType === 'hex' && a === 1)
                ? `#${colorObj.toHex(true)}`
                : a === 1
                    ? `rgb(${r}, ${g}, ${b})`
                    : `rgba(${r}, ${g}, ${b}, ${a})`;

            this.selectedColorRGB = colorObj.toRgb();
            this.val = Colors._colorChange(this.selectedColorRGB);
        },
        resetColorDetails() {
            this.selectedColorName = '';
            this.selectedColorRGB = '';
            this.val = Colors._colorChange('');
        },
        selectColor(key, hex) {
            this.selectedColorKey = key;
            this.updateColorDetails(hex);
        },
        setColorName(colorObj) {
            const { r, g, b, a } = colorObj.toRgb();
            this.selectedColorName = (this.selectedType === 'hex' && a === 1)
                ? `#${colorObj.toHex(true)}`
                : a === 1
                    ? `rgb(${r}, ${g}, ${b})`
                    : `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        changeData(colorVal) {
            const colorObj = tinycolor(colorVal);

            if (colorObj.getAlpha() === 1) {
                colorVal = `#${  colorObj.toHex(true)}`; // true allows for the hex color to be less than 6 characters
            } else {
                colorVal = colorObj.getOriginalInput();
            }

            this.setColorName(colorObj);
            if (this.colorsDisplayed[this.selectedColorKey]) {
                this.colorsDisplayed[this.selectedColorKey].hex = colorVal;
            }
            this.$store.dispatch('setSelectedColors', this.colorsDisplayed);
            Colors.addCssRulesFromPalette(this.colorsDisplayed);
        },
        colorChange(data, oldHue) {
            // if we're not setting the opacity always set it to 1
            if (data.source !== 'rgba') {
                data.a = 1;
            }
            this.colorsModel = Colors._colorChange(data, oldHue || this.colorsModel.hsl.h);
        },
        changeDisplayedColor(payload) {
            const format = payload.data.value;
            this.selectedType = format;

            if (!this.selectedColorName) return;

            const colorObj = tinycolor(this.selectedColorName);
            this.selectedColorName = format === 'hex'
                ? `#${colorObj.toHex(true)}`
                : colorObj.toRgbString();
        },
        updateColorSelected(payload) {
            const newColor = payload.data.value.trim();
            const colorObj = tinycolor(newColor);

            if (colorObj.isValid()) {
                this.updateColorDetails(newColor);
                this.colorsDisplayed[this.selectedColorKey].hex = `#${colorObj.toHex(true)}`;
                Colors.addCssRulesFromPalette(this.colorsDisplayed);
            }
        },
        applySavedColor(color) {
            if (this.selectedColorKey) {
                this.updateColorDetails(color);
                this.colorsDisplayed[this.selectedColorKey].hex = color;
                Colors.addCssRulesFromPalette(this.colorsDisplayed);
            }
        },
    }
};
</script>
