import { cloneDeep, merge } from 'lodash';
import { $ss } from '../plugins/localstorage';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    modules: [],
    currentStepIndex: 0,
    stepsData: {},
    stepsDone: {},
    stepsReady: {},
    stepHistory: [],
    lastStep: -1,
    isAIEnabled: false,
    isAIFlow: false,
    currentStepSettings: {
        hideNext: false,
        hideFooter: false
    },
    logo: 'imgs/logo.png',
    flowFinished: false,
    clearGlobalData: false,
    globalData: {
        companyName: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        zip: '',
        state: '',
        country: '',
        orgNumber: '',
        startDate: '',
        descriptionShort: '', 
        descriptionLong: '', 
        mission: '', 
        logo: '',
        services: '',
        siteLanguage: '',
        businessType: '',
        products: ''
    },
    accountData: {
        customerId: ''
    },
    selectedTemplateInfo: {},
    templateId: '',
    sessionId: '',
    defaultCountry: '',
    defaultLanguage: '',
    previewLink: '',
    gmbPhoneCountryCode: '',
    accountPhoneCountryCode: '',
    AIData: {},
    GMBData: [],
    storedLocation: [],
    isManualInput: false,
    isAIAPIDown: false,
    nextButtonLoading: false,
    noCreditsFound: false,
    storedImages: {},
    imagesToSend: {},
    selectedColors: {},
    templates: [],
    templateGlobalColors: {},
    savedColors: {
        color_1: '',
        color_2: '',
        color_3: '',
        color_4: '',
        color_5: '',
        color_6: '',
        color_7: '',
        color_8: '',
        color_9: '',
    }
};

const mutations = {
    ADD_STEP_HISTORY(state, stepIndex) {
        state.stepHistory.push(stepIndex);
    },
    REMOVE_STEP_HISTORY(state) {
        state.stepHistory.pop();
    },
    CLEAR_STEP_HISTORY() {
        state.stepHistory = [];
    },
    SET_SETTINGS(state, newState) {
        for (const key in newState) {
            Vue.set(state, key, newState[key]);
        }
    },
    SET_STEPDONE(state, obj) {
        const newObj = {};
        newObj[obj.index] = obj.done;

        Vue.set(state, 'stepsDone', merge(cloneDeep(state.stepsDone), newObj));
        $ss.set('stepsDone', state.stepsDone);
    },
    SET_STEPREADY(state, obj) {
        const newObj = {};
        newObj[obj.index] = obj.ready;

        Vue.set(state, 'stepsReady', merge(cloneDeep(state.stepsReady), newObj));
    },
    SET_STEPDATA(state, obj) {
        Vue.set(state, 'stepsData', merge(cloneDeep(state.stepsData), obj));
        $ss.set('stepsData', state.stepsData);
    },
    SET_ISAIENABLED(state, isAIEnabled) {
        state.isAIEnabled = isAIEnabled;
    },
    SET_ISAIFLOW(state, isAIFlow) {
        state.isAIFlow = isAIFlow;
    },
    SET_LASTSTEP(state, lastStep) {
        state.lastStep = lastStep;
    },
    SET_FLOWFINISHED(state, finished) {
        state.flowFinished = finished;
    },
    SET_HEADERISHIDDEN(state, hidden) {
        state.headerIsHidden = hidden;
    },
    SET_FOOTERISHIDDEN(state, hidden) {
        state.footerIsHidden = hidden;
    },
    SET_CURRENTSTEPSETTINGS(state, stepSettings) {
        state.currentStepSettings = stepSettings;
    },
    SET_GLOBALDATA(state, globalData) {
        state.globalData = Object.assign({}, state.globalData, globalData);
    },
    SET_CLEARGLOBALDATA(state, clearGlobalData) {
        state.clearGlobalData = clearGlobalData;
    },
    SET_ACCOUNTDATA(state, accountData) {
        state.accountData = Object.assign({}, state.accountData, accountData);
    },
    SET_SELECTEDTEMPLATEINFO(state, selectedTemplateInfo) {
        state.selectedTemplateInfo = selectedTemplateInfo;
    },
    SET_TEMPLATEID(state, templateId) {
        state.templateId = templateId;
    },
    SET_CURRENTSTEPINDEX(state, stepIndex) {
        state.currentStepIndex = stepIndex;
    },
    SET_SESSIONID(state, sessionId) {
        state.sessionId = sessionId;
    },
    SET_DEFAULTCOUNTRY(state, defaultCountry) {
        state.defaultCountry = defaultCountry;
    },
    SET_DEFAULTLANGUAGE(state, defaultLanguage) {
        state.defaultLanguage = defaultLanguage;
    },
    SET_GMBPHONECOUNTRYCODE(state, gmbPhoneCountryCode) {
        state.gmbPhoneCountryCode = gmbPhoneCountryCode;
    },
    SET_ACCOUNTPHONECOUNTRYCODE(state, accountPhoneCountryCode) {
        state.accountPhoneCountryCode = accountPhoneCountryCode;
    },
    SET_LOGO(state, logo) {
        state.logo = logo;
    },
    SET_PREVIEWLINK(state, previewLink) {
        state.previewLink = previewLink;
    },
    SET_AIDATA(state, aiData) {
        state.AIData = aiData;
    },
    SET_GMBDATA(state, GMBData) {
        state.GMBData = GMBData;
    },
    SET_STOREDLOCATION(state, storedLocation) {
        state.storedLocation = storedLocation;
    },
    SET_ISMANUALINPUT(state, isManualInput) {
        state.isManualInput = isManualInput;
    },
    SET_ISAIAPIDOWN(state, isAIAPIDown) {
        state.isAIAPIDown = isAIAPIDown;
    },
    SET_NOCREDITSFOUND(state, noCreditsFound) {
        state.noCreditsFound = noCreditsFound;
    },
    SET_NEXTBUTTONLOADING(state, nextButtonLoading) {
        state.nextButtonLoading = nextButtonLoading;
    },
    SET_STOREDIMAGES(state, storedImages) {
        state.storedImages = storedImages;
    },
    SET_IMAGESTOSEND(state, imagesToSend) {
        state.imagesToSend = imagesToSend;
    },
    SET_SELECTEDCOLORS(state, selectedColors) {
        state.selectedColors = selectedColors;
    },
    SET_TEMPLATES(state, templates) {
        state.templates = templates;
    },
    SET_TEMPLATEGLOBALCOLORS(state, templateGlobalColors) {
        state.templateGlobalColors = templateGlobalColors;
    },
    SET_SAVEDCOLORS(state, { key, color }) {
        if (state.savedColors[key] !== undefined) {
            state.savedColors[key] = color;
        }
        $ss.set('savedColors', state.savedColors);
    }
};

//Set steps data already saved from sessionStorage
if ($ss.get('stepsData')) {
    Vue.set(state, 'stepsData', $ss.get('stepsData'));
}

//Set sessionId already saved from sessionStorage
if ($ss.get('loginToken')) {
    Vue.set(state, 'sessionId', $ss.get('loginToken'));
}

//Set defaultCountry already saved from sessionStorage
if ($ss.get('defaultCountry')) {
    Vue.set(state, 'defaultCountry', $ss.get('defaultCountry'));
}

//Set isAIEnabled already saved from sessionStorage
if ($ss.get('isAIEnabled')) {
    Vue.set(state, 'isAIEnabled', $ss.get('isAIEnabled'));
}

//Set savedColors already saved from sessionStorage
if ($ss.get('savedColors')) {
    Vue.set(state, 'savedColors', $ss.get('savedColors'));
}

const actions = {
    'addStepHistory'({ commit }, stepIndex) {
        commit('ADD_STEP_HISTORY', stepIndex);
    },
    'removeStepHistory'({ commit }, stepIndex) {
        commit('REMOVE_STEP_HISTORY', stepIndex);
    },
    'clearStepHistory'({ commit }) {
        commit('CLEAR_STEP_HISTORY');
    },
    'setSettings'({ commit }, settings) {
        commit('SET_SETTINGS', settings);
    },
    'updateStepDone'({ commit }, obj) {
        commit('SET_STEPDONE', obj);
    },
    'updateStepReady'({ commit }, obj) {
        commit('SET_STEPREADY', obj);
    },
    'updateStepData'({ commit }, obj) {
        commit('SET_STEPDATA', obj);
    },
    'updateLastStep'({ commit }, lastStep) {
        commit('SET_LASTSTEP', lastStep);
    },
    'setIsAIEnabled'({ commit }, isAIEnabled) {
        commit('SET_ISAIENABLED', isAIEnabled);
    },
    'setIsAIFlow'({ commit }, isAIFlow) {
        commit('SET_ISAIFLOW', isAIFlow);
    },
    'setFlowFinished'({ commit }, finished) {
        commit('SET_FLOWFINISHED', finished);
    },
    'setHeaderIsHidden'({ commit }, hidden) {
        commit('SET_HEADERISHIDDEN', hidden);
    },
    'setFooterIsHidden'({ commit }, hidden) {
        commit('SET_FOOTERISHIDDEN', hidden);
    },
    'setCurrentStepSettings'({ commit }, stepSettings) {
        commit('SET_CURRENTSTEPSETTINGS', stepSettings);
    },
    'setGlobalData'({ commit }, globalData) {
        commit('SET_GLOBALDATA', globalData);
    },
    'setClearGlobalData'({ commit }, clearGlobalData) {
        commit('SET_CLEARGLOBALDATA', clearGlobalData);
    },
    'setAccountData'({ commit }, accountData) {
        commit('SET_ACCOUNTDATA', accountData);
    },
    'setSelectedTemplateInfo'({ commit }, selectedTemplateInfo) {
        commit('SET_SELECTEDTEMPLATEINFO', selectedTemplateInfo);
    },
    'setTemplateId'({ commit }, templateId) {
        commit('SET_TEMPLATEID', templateId);
    },
    'setCurrentStepIndex'({ commit }, stepIndex) {
        commit('SET_CURRENTSTEPINDEX', stepIndex);
    },
    'setSessionId'({ commit }, sessionId) {
        commit('SET_SESSIONID', sessionId);
    },
    'setDefaultCountry'({ commit }, defaultCountry) {
        commit('SET_DEFAULTCOUNTRY', defaultCountry);
    },
    'setDefaultLanguage'({ commit }, defaultLanguage) {
        commit('SET_DEFAULTLANGUAGE', defaultLanguage);
    },
    'setGmbPhoneCountryCode'({ commit }, gmbPhoneCountryCode) {
        commit('SET_GMBPHONECOUNTRYCODE', gmbPhoneCountryCode);
    },
    'setAccountPhoneCountryCode'({ commit }, accountPhoneCountryCode) {
        commit('SET_ACCOUNTPHONECOUNTRYCODE', accountPhoneCountryCode);
    },
    'setLogo'({ commit }, logo) {
        commit('SET_LOGO', logo);
    },
    'setPreviewLink'({ commit }, previewLink) {
        commit('SET_PREVIEWLINK', previewLink);
    },
    'setAIData'({ commit }, aiData) {
        commit('SET_AIDATA', aiData);
    },
    'setGMBData'({ commit }, GMBData) {
        commit('SET_GMBDATA', GMBData);
    },
    'setStoredLocation'({ commit }, storedLocation) {
        commit('SET_STOREDLOCATION', storedLocation);
    },
    'setIsManualInput'({ commit }, isManualInput) {
        commit('SET_ISMANUALINPUT', isManualInput);
    },
    'setIsAIAPIDown'({ commit }, isAIAPIDown) {
        commit('SET_ISAIAPIDOWN', isAIAPIDown);
    },
    'setNoCreditsFound'({ commit }, noCreditsFound) {
        commit('SET_NOCREDITSFOUND', noCreditsFound);
    },
    'setNextButtonLoading'({ commit }, nextButtonLoading) {
        commit('SET_NEXTBUTTONLOADING', nextButtonLoading);
    },
    'setStoredImages'({ commit }, storedImages) {
        commit('SET_STOREDIMAGES', storedImages);
    },
    'setImagesToSend'({ commit }, imagesToSend) {
        commit('SET_IMAGESTOSEND', imagesToSend);
    },
    'setSelectedColors'({ commit }, selectedColors) {
        commit('SET_SELECTEDCOLORS', selectedColors);
    },
    'setTemplates'({ commit }, templates) {
        commit('SET_TEMPLATES', templates);
    },
    'setTemplateGlobalColors'({ commit }, templateGlobalColors) {
        commit('SET_TEMPLATEGLOBALCOLORS', templateGlobalColors);
    },
    'setSavedColors'({ commit }, { key, color }) {
        if (key && color) {
            commit('SET_SAVEDCOLORS', { key, color });
        }
    },
};

const getters = {
    modules: state => state.modules,
    stepsData: state => state.stepsData,
    stepsDone: state => state.stepsDone,
    stepsReady: state => state.stepsReady,
    stepHistory: state => state.stepHistory,
    lastStep: state => state.lastStep,
    isAIEnabled: state => state.isAIEnabled,
    isAIFlow: state => state.isAIFlow,
    logo: state => state.logo,
    flowFinished: state => state.flowFinished,
    headerIsHidden: state => state.headerIsHidden,
    footerIsHidden: state => state.footerIsHidden,
    currentStepSettings: state => state.currentStepSettings,
    globalData: state => state.globalData,
    accountData: state => state.accountData,
    clearGlobalData: state => state.clearGlobalData,
    selectedTemplateInfo: state => state.selectedTemplateInfo,
    templateId: state => state.templateId,
    currentStepIndex: state => state.currentStepIndex,
    sessionId: state => state.sessionId,
    defaultCountry: state => state.defaultCountry,
    defaultLanguage: state => state.defaultLanguage,
    gmbPhoneCountryCode: state => state.gmbPhoneCountryCode,
    accountPhoneCountryCode: state => state.accountPhoneCountryCode,
    previewLink: state => state.previewLink,
    AIData: state => state.AIData,
    GMBData: state => state.GMBData,
    storedLocation: state => state.storedLocation,
    isManualInput: state => state.isManualInput,
    isAIAPIDown: state => state.isAIAPIDown,
    noCreditsFound: state => state.noCreditsFound,
    nextButtonLoading: state => state.nextButtonLoading,
    storedImages: state => state.storedImages,
    imagesToSend: state => state.imagesToSend,
    selectedColors: state => state.selectedColors,
    templates: state => state.templates,
    templateGlobalColors: state => state.templateGlobalColors,
    savedColors: state => state.savedColors
};

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters
});

export default store;