export default {
    data() {
        return {
            filterData: [],
            filterType: 'STANDARD',
            loadingTemplates: false
        };
    },
    computed: {
        filteredTemplates() {
            let filteredList = this.templates;
            if (this.filterData.length) {
                filteredList = filteredList.filter(template => {
                    if (!template.templateHeadings) {
                        return false;
                    }
                    const category = template.templateHeadings.map(heading => heading.translationKey);
                    const intersection = this.filterData.filter(x => category.includes(x));
                    return intersection.length > 0;
                });
            }
            if (this.filterType.length) {
                filteredList = filteredList.filter(template => this.filterType.includes(template.type.toUpperCase()));
            }
            return filteredList;
        }
    },
    methods: {
        setFilterData(filterData) {
            if (filterData === 'AI' || filterData === 'STANDARD') {
                this.filterType = filterData;
                return;
            }
            this.filterData = filterData;
        },
        clearTemplate() {
            this.$store.dispatch('setSelectedTemplateInfo', {});
            this.$store.dispatch('setTemplateId', '');
            this.$store.dispatch('setSelectedColors', {});
        }
    }
};