<template>
    <div class="themesViewPalette">
        <div class="themesContent">
            <span class="preamble">{{ $t("quickCreator.templateSelection.customColors.preamble") }}</span>
            <color-picker />
        </div>
    </div>
</template>

<script>
import ColorPicker from './ColorPicker.vue';

export default {
    name: 'qc-custom-colors',
    components: { ColorPicker },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
};
</script>
