<template>
    <div class="themesViewPalette">
        <div class="themesContent">
            <span class="preamble">{{ $t("quickCreator.templateSelection.templatePicker.preamble") }}</span>
            <div class="filterWrapper">
                <template-filter-new
                    :tag-templates="templates"
                    :has-toggle="isMobile"
                    title="quickCreator.templateSelection.filterTitle"
                    @filterUpdated="setFilterData" />
            </div>
            <div class="templateChooserWrapper">
                <div v-if="loading" class="loadingWrap">
                    <div class="loading" />
                </div>
                <template-chooser 
                    :templates="filteredTemplates"
                    :selected-template-id="selectedTemplateId"
                    @templateSelect="applyTemplate" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QCModuleMixin from '../mixins/QCModuleMixin';
import TemplateSelectMixin from '../mixins/TemplateSelectMixin';
import TemplateFilterNew from './TemplateFilterNew.vue';
import TemplateChooser from './TemplateChooser.vue';

export default {
    name: 'qc-template-picker',
    components: { TemplateFilterNew, TemplateChooser },
    mixins: [ TemplateSelectMixin, QCModuleMixin ],
    props: {
        templates: {
            type: Array,
            'default': []
        },
        thumbPath: {
            type: String,
            'default': 'assets/themes/'
        }
    },
    data() {
        return {
            loading: false,
            selectedTemplateId: 0
        }
    },
    mounted() {
        this.selectedTemplateId = Number(this.templateId);
        if (this.selectedTemplateId) {
            this.setStepReady(true);
            this.setStepDone(true);
        }
    },
    computed: {
        ...mapGetters({
            templateId: 'templateId',
            isAIFlow: 'isAIFlow',
            isAIEnabled: 'isAIEnabled'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        }
    },
    watch: {
        templateId(value) {
            this.$store.dispatch('setTemplateId', value);
            if (this.templateId === '') {
                this.selectedTemplateId = 0;
                this.$store.dispatch('setTemplateId', '');
                this.$store.dispatch('setSelectedColors', {});
                this.$store.dispatch('setSelectedTemplateInfo', {});
                this.setStepDone(false);
                this.setStepReady(false);
            }
        }
    },
    methods: {
        async applyTemplate(template = this.currentTemplate) {
            this.loading = true;

            this.selectedTemplateId = template.id;
            this.$store.dispatch('setTemplateId', this.selectedTemplateId);
            this.$store.dispatch('setSelectedTemplateInfo', template);
            try {
                this.loading = false;

                this.setStepReady(true);
                this.setStepDone(true);
            } catch (error) {
                this.setStepDone(false);
            }
        }
    }
};
</script>
