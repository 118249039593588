<template>
    <div class="moduleWrapper templateSelection">
        <div class="sideContent">
            <preview-overlay-palette v-if="Object.keys(this.selectedTemplateInfo).length !== 0"
                :show-combined-viewports="false" :url="previewUrl" name="previewOverlay" />
            <div v-else class="noTemplate">
                <img :src="'../images/' + sidebarSelectTemplateImg" class="illustration">
                <span>{{ $t("quickCreator.templateSelection.preamble") }}</span>
            </div>
        </div>
        <div class="contents">
            <div class="templateWrapper" :class="{ loading: loadingTemplates }">
                <editor-accordion v-for="(accordion, index) in accordions"
                    :key="index"
                    :index="index"
                    :title="accordion.title"
                    :class="accordion.customClass ? accordion.customClass : ''"
                    :is-controlled="true"
                    :controlled-expand-state="openIndex === index"
                    @accordion-clicked="setOpenIndex">
                    <template #mv-editor-accordion-content>
                        <component :is="accordion.component" v-bind="accordion.props" />
                    </template>
                </editor-accordion>
            </div>
            <mv-icon-sprite />
        </div>
    </div>
</template>

<script>
import { getTemplates } from "@/services/api";
import { mapGetters } from 'vuex';
import { MvIconSprite, EditorAccordion } from "@monosolutions/vue-components";
import QCModuleMixin from '../mixins/QCModuleMixin';
import PreviewOverlayPalette from '../components/PreviewOverlayPalette.vue';
import TemplatePicker from '../components/TemplatePicker.vue';
import ColorPalettes from '../components/ColorPalettes.vue';
import CustomColors from '../components/CustomColors.vue';

export default {
    name: 'qc-template-selection-new',
    components: { MvIconSprite, EditorAccordion, TemplatePicker, ColorPalettes, PreviewOverlayPalette, CustomColors },
    mixins: [QCModuleMixin],
    props: {
        thumbPath: {
            type: String,
            'default': 'assets/themes/'
        }
    },
    data() {
        return {
            sidebarSelectTemplateImg: '1x-Guywithpencil.png',
            loadingTemplates: false,
            selectedTemplateId: 0,
            previewUrl: '',
            hideColors: true,
            openIndex: null,
            accordions: [
                {
                    title: this.$t("quickCreator.templateSelection.headline"),
                    component: "template-picker",
                    props: {
                        templates: this.templates,
                    },
                    customClass: "",
                },
                {
                    title: this.$t("quickCreator.templateSelection.accordions.colorPalettes"),
                    component: "color-palettes",
                    props: {},
                    customClass: "deactivated",
                },
                {
                    title: this.$t("quickCreator.templateSelection.accordions.customColors"),
                    component: "custom-colors",
                    props: {},
                    customClass: "deactivated",
                },
            ]
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            selectedTemplateInfo: 'selectedTemplateInfo',
            selectedColors: 'selectedColors',
            templates: 'templates'
        }),
    },
    watch: {
        templateId(value) {
            this.$store.dispatch('setTemplateId', value);
            this.previewTemplate(this.selectedTemplateInfo);
            this.accordions[1].customClass = !this.selectedTemplateInfo.paletteReady ? 'deactivated' : '';
            this.accordions[2].customClass = !this.selectedTemplateInfo.paletteReady && Object.keys(this.selectedTemplateInfo.globalColors).length >= 0 ? 'deactivated' : '';
            if (this.templateId === '') {
                this.selectedTemplateId = 0;
                this.$store.dispatch('setTemplateId', '');
                this.$store.dispatch('setSelectedColors', {});
                this.$store.dispatch('setSelectedTemplateInfo', {});
                this.setStepDone(false);
                this.setStepReady(false);
            } else if (this.templateId !== '' && this.selectedTemplateInfo.paletteReady) {
                const templateColors = {};
                const globalColors = this.selectedTemplateInfo.globalColors;
                Object.entries(globalColors).slice(0, 8).forEach(([key, value]) => {
                    if (value) {
                        templateColors[key] = { hex: value };
                    }
                });
                this.$store.dispatch('setTemplateGlobalColors', templateColors);
            }
        },
    },
    async mounted() {
        this.loadingTemplates = true;
        if (!this.templates.length) {
            const apiTemplates = await getTemplates(this.sessionId);
            this.$store.dispatch('setTemplates', apiTemplates);
        }
        if (Object.keys(this.selectedTemplateInfo).length !== 0) {
            this.previewTemplate(this.selectedTemplateInfo);
            this.accordions[1].customClass = !this.selectedTemplateInfo.paletteReady ? 'deactivated' : '';
            this.accordions[2].customClass = !this.selectedTemplateInfo.paletteReady ? 'deactivated' : '';
        }
        this.loadingTemplates = false;
        if (this.selectedTemplateId) {
            this.setStepReady(true);
            this.setStepDone(true);
        }
        this.fillAccordion();
    },
    methods: {
        fillAccordion() {
            this.accordions = [
                {
                    title: this.$t("quickCreator.templateSelection.headline"),
                    component: "template-picker",
                    props: {
                        templates: this.templates,
                    },
                    customClass: "",
                },
                {
                    title: this.$t("quickCreator.templateSelection.accordions.colorPalettes"),
                    component: "color-palettes",
                    props: {},
                    customClass: "deactivated",
                },
                {
                    title: this.$t("quickCreator.templateSelection.accordions.customColors"),
                    component: "custom-colors",
                    props: {},
                    customClass: "deactivated",
                },
            ];
        },
        setOpenIndex(index) {
            this.openIndex = this.openIndex === index ? null : index;
        },
        previewTemplate(template) {
            this.previewUrl = template?.templatePreviewUrl?.replace(/^https:/, "");
        },
    }
};
</script>
