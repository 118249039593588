<template>
    <div class="preview-overlay">
        <div class="overlay-header" @wheel.stop>
            <div class="viewportsSwitchContainer">
                <div v-if="showCombinedViewports" :class="{ 'active': activeViewport === 'all' }" class="allViewports"
                    @click="setVisibleViewport('all')">
                    <i class="mf-desktop" />
                    <i class="mf-tablet" />
                    <i class="mf-mobile" />
                </div>
                <i :class="{ 'active': activeViewport === 'desktop' }" class="mf-desktop"
                    @click="setVisibleViewport('desktop')" />
                <i :class="{ 'active': activeViewport === 'tablet' }" class="mf-tablet"
                    @click="setVisibleViewport('tablet')" />
                <i :class="{ 'active': activeViewport === 'mobile' }" class="mf-mobile"
                    @click="setVisibleViewport('mobile')" />
            </div>
        </div>
        <div class="overlay-content" @wheel.stop>
            <div v-if="templateLoading" class="loadingWrap">
                <div class="loading" />
            </div>
            <div v-if="!templateLoading"
                :class="[{ 'singleViewport': !allViewportsVisible }, activeViewport + 'Active']"
                class="viewportsPreview">
                <div v-show="visibleViewports.desktop.visible" class="column desktopFrame">
                    <iframe id="iframe-desktop" ref="desktopContent" class="viewportContent"
                        :srcdoc="templateHtmlContent"></iframe>
                </div>
                <div v-show="visibleViewports.tablet.visible" class="column tabletFrame">
                    <iframe id="iframe-tablet" ref="tabletContent" class="viewportContent"
                        :srcdoc="templateHtmlContent"></iframe>
                </div>
                <div v-show="visibleViewports.mobile.visible" class="column mobileFrame">
                    <iframe id="iframe-mobile" ref="mobileContent" class="viewportContent"
                        :srcdoc="templateHtmlContent"></iframe>
                </div>
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
import { getTemplateContentById } from "@/services/api";
import { mapGetters } from 'vuex';
import { every } from 'lodash';

export default {
    name: 'preview-overlay',
    props: {
        url: {
            type: String,
            default: () => '',
        },
        showCombinedViewports: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            visibleViewports: {
                desktop: { visible: true },
                tablet: { visible: this.showCombinedViewports },
                mobile: { visible: this.showCombinedViewports },
            },
            activeViewport: this.showCombinedViewports ? 'all' : 'desktop',
            templateHtmlContent: '',
            templateLoading: false
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            templateId: 'templateId',
        }),
        allViewportsVisible() {
            return every(this.visibleViewports, 'visible');
        },
    },
    watch: {
        templateId: {
            immediate: true,
            async handler(newTemplateId) {
                if (newTemplateId) {
                    this.templateHtmlContent = await this.fetchTemplateContent(newTemplateId);
                }
            },
        },
    },
    methods: {
        async fetchTemplateContent(templateId) {
            this.templateLoading = true;
            try {
                const content = await getTemplateContentById(this.sessionId, templateId);

                const updatedContent = content
                    .replace(/href="\/(?!\/)/g, `href="${this.url}/`)
                    .replace(/src="\/(?!\/)/g, `src="${this.url}/`);

                const contentWithBase = updatedContent.replace(
                    '<head>',
                    `<head><base href="${this.url}">`
                );

                this.templateLoading = false;
                return contentWithBase;
            } catch (error) {
                this.templateLoading = false;
                console.error('Error fetching template content:', error);
                return '<p>Error loading content</p>';
            }
        },

        setVisibleViewport(vp) {
            if (vp === 'all') {
                for (const v in this.visibleViewports) {
                    this.visibleViewports[v].visible = true;
                }
                this.activeViewport = 'all';
            } else {
                for (const v in this.visibleViewports) {
                    this.visibleViewports[v].visible = false;
                }
                this.visibleViewports[vp].visible = true;
                this.activeViewport = vp;
            }
        },
        close() {
            this.setVisibleViewport(this.showCombinedViewports ? 'all' : 'desktop');
        },
        applyTemplate() {
            this.$emit('applyTemplate');
            this.close();
        },
        switchHeaderAndContent() {
            const header = document.getElementsByClassName('overlay-header');
            const content = document.getElementsByClassName('overlay-content');
            if (header.length > 0 && content.length > 0) {
                if (this.$mq === 'mobile') {
                    content[0].after(header[0]);
                } else {
                    header[0].after(content[0]);
                }
            }
        },
    },
};
</script>
