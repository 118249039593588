<template>
    <div class="saved-colors">
        <div class="color-square add-color" :class="{ disabled: !canAddColor }" @click="addColor">
            <mv-icon type="plus" />
        </div>
        <div v-for="(color, key) in savedColors" :key="key" :style="{ backgroundColor: color || 'transparent' }"
            class="color-square" @click="selectSavedColor(color)">
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MvIcon } from "@monosolutions/vue-components";

export default {
    name: 'qc-saved-colors',
    components: { MvIcon },
    props: {
        selectedColorName: {
            type: String,
            default: ''
        },
    },
    computed: {
        ...mapGetters({
            savedColors: 'savedColors'
        }),
        canAddColor() {
            return (
                this.selectedColorName &&
                !Object.values(this.savedColors).includes(this.selectedColorName)
            );
        },
    },
    methods: {
        async addColor() {
            const colorAlreadyExists = Object.values(this.savedColors).includes(this.selectedColorName);
            if (colorAlreadyExists) {
                return;
            }

            try {
                const keys = Object.keys(this.savedColors);
                const values = Object.values(this.savedColors);

                const emptyIndex = values.findIndex(color => !color);

                if (emptyIndex !== -1) {
                    await this.$store.dispatch('setSavedColors', { key: keys[emptyIndex], color: this.selectedColorName });
                } else {
                    const newColors = [this.selectedColorName, ...values.slice(0, -1)];

                    for (let i = 0; i < keys.length; i++) {
                        await this.$store.dispatch('setSavedColors', { key: keys[i], color: newColors[i] });
                    }
                }
            } catch (error) {
                console.error('Error adding color:', error);
            }
        },
        selectSavedColor(color) {
            if (color) {
                this.$emit('select-saved-color', color);
            }
        }
    }
};
</script>
