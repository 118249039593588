<template>
    <div class="templateChooser">
        <ul class="clear">
            <template-preview v-for="(template, i) in templates"
                :key="i"
                :template-id="template.id"
                :thumb-path="template.thumbnailUrl"
                :selected-template-id="selectedTemplateId"
                @selectTemplate="$emit('templateSelect', template)" />
        </ul>
    </div>
</template>

<script>
import TemplatePreview from '../components/TemplatePreview.vue';
export default {
    name: 'template-chooser',
    components: { TemplatePreview },
    props:{
        templates: {
            type: Array,
            default: () => {
                return [];
            }
        },
        selectedTemplateId: {
            type: Number,
            default: 0
        }
    }
};
</script>