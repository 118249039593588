<template>
    <li :data-templateid="templateId" class="template" :class="{ 'selected': isSelected(templateId) }">
        <img :src="thumbPath" :alt="`template-${templateId}`" :class="{ 'selected': isSelected(templateId) }">
        <div class="buttonsLayer">
            <div class="buttonsContainer">
                <mv-button text="general.select" color="blue" @click="selectTemplate(templateId)" />
            </div>
        </div>
        <div v-if="isSelected(templateId)" class="checkbox p-8 text-right">
            <label class="mds-radio">
                <input
                class="mds-radio-wrapper"
                type="radio"
                :name="`template-${templateId}`"
                :value="templateId"
                :checked="isSelected(templateId)">
                <span class="mds-radio-selector">
                    <mv-icon type="check" />
                </span>
                <span class="mds-radio-label"></span>
            </label>
        </div>
    </li>
</template>

<script>
import { MvButton, MvIcon } from "@monosolutions/vue-components";
export default {
    name: 'template-preview',
    components: { MvButton, MvIcon },
    props: {
        thumbPath: {
            type: String,
            'default': ''
        },
        templateId: {
            type: Number,
            'default': 0
        },
        selectedTemplateId: {
            type: Number,
            default: 0
        }
    },
    methods: {
        isSelected(templateId) {
            return this.selectedTemplateId === templateId;
        },
        selectTemplate(templateId) {
            this.$emit('selectTemplate', templateId);
        }
    }
};
</script>
